const redirects = {
   'url1' : { landing_page : 'https://sos-barnbyar.se/foretag/', name: "Landningssida företag" },
   'url2' : { landing_page : 'https://sos-barnbyar.se/julgava-foretag-1/', name: "Julgåva 1" },
   'url3' : { landing_page : 'https://sos-barnbyar.se/julgava-foretag-2/', name: "Julgåva 2" },
   'url4' : { landing_page : 'https://sos-barnbyar.se/julgava-foretag-3/', name: "Julgåva 3" },
   'url5' : { landing_page : 'https://sos-barnbyar.se/barn-i-sudan-kanner-sig-overgivna-av-omvarlden/', name: "Case: Malik" },

   'url6' : { landing_page : 'https://sos-barnbyar.se/julgava-foretag-1/', name: "Julgåva 1" },
   'url7' : { landing_page : 'https://sos-barnbyar.se/poesin-gav-nour-livet-tillbaka/', name: "Case: Nour" },

   'url8' : { landing_page : 'https://sos-barnbyar.se/julgava-foretag-1/', name: "Julgåva 1" },
   'url9' : { landing_page : 'https://sos-barnbyar.se/julgava-foretag-2/', name: "Julgåva 2" },
   'url10' : { landing_page : 'https://sos-barnbyar.se/julgava-foretag-3/', name: "Julgåva 3" },
   'url11' : { landing_page : 'https://sos-barnbyar.se/poesin-gav-nour-livet-tillbaka/', name: "Case: Nour" },

   'url12' : { landing_page : 'https://sos-barnbyar.se/barn-i-sudan-kanner-sig-overgivna-av-omvarlden/', name: "Case: Sudan" },

   'url13' : { landing_page : 'https://sos-barnbyar.se/en-nyfodd-bebis-i-gaza-skulle-snart-do/', name: "Case: Gaza" },

   'url14' : { landing_page : '	https://sos-barnbyar.se/maysa-tre-ar-hittades-helt-ensam-i-krigets-gaza/', name: "Case: Maysa" },

   'url15' : { landing_page : 'https://sos-barnbyar.se/julgava-foretag-1/', name: "Julgåva 1" },
   'url16' : { landing_page : 'https://sos-barnbyar.se/julgava-foretag-2/', name: "Julgåva 2" },
   'url17' : { landing_page : 'https://sos-barnbyar.se/julgava-foretag-3/', name: "Julgåva 3" },

   'url18' : { landing_page : 'https://sos-barnbyar.se/foretagsgava-gaza-1/?utm_source=clkdim&utm_medium=email&utm_campaign=Dubblering25&utm_content=Reaktion', name: "Gaza 1" },

};

export default redirects